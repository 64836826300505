import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { useStaticQuery, graphql } from 'gatsby'

import GlobalStyles from './global-styles'
import Image from './figure-image'
import Obfuscate from 'react-obfuscate'
import * as tagDefaults from './markdown-defaults'
import { Helmet } from 'react-helmet'

const componentOverrides = {
  ...tagDefaults,
  Image,
  Obfuscate,
}

const query = graphql`
  query contextMetaTags {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`

// Component used to set the global context of the app
// e.g. default meta tags and how to render the HTML
// generated from markdown files (currently uses defaults).
// Pages are in light theme by default, see GlobalStyles.
export default function AppContext(props) {
  const { author, title } = useStaticQuery(query).site.siteMetadata

  return (
    <MDXProvider components={componentOverrides}>
      {props.children}
      <GlobalStyles />
      <Helmet defaultTitle={title} titleTemplate="%s | ACT of ESA">
        {/*
          Fix css active for mobile safari
          https://stackoverflow.com/questions/3885018/active-pseudo-class-doesnt-work-in-mobile-safari
          */}
        <body ontouchstart="" />
        <html lang="en" />
        {/* meta-tags that should be on every page */}
        <meta httpEquiv="content-language" content="en" />
        <meta name="generator" content="esa" />
        <meta name="copyright" content="esa" />
        <meta name="application-name" content={author} />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="noodp" />
        <meta name="google" content="nositelinkssearchbox" />
        <meta name="google" content="notranslate" />
        <meta name="revisit-after" content="3 days" />
        <meta name="author" content={author} />
        <meta name="web_author" content={author} />
        <meta
          name="keywords"
          content="European Space Agency, ACT, Advanced Concepts Team, space exploration, probes, satellites, solar system, universe, space news, ESA, astronomy, education, nano, bio, ai, Artificial Intelligence, science, research, Ariadna, Acta Futura, multidisciplinary, think-tank, innovation, out of the box, disruptive innovation"
        />
      </Helmet>
    </MDXProvider>
  )
}
