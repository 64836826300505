// These screen widths are used to set different
// css styles when needed to create a responsive UI.
export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

const screen = {}

for (const [key, width] of Object.entries(breakpoints)) {
  // Why breakpoints should be in em units: https://zellwk.com/blog/media-query-units/
  screen[key] = `(min-width: ${width / 16}em)`
}

export { screen }
