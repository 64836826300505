exports.components = {
  "component---src-layouts-acta-futura-js": () => import("./../../../src/layouts/acta-futura.js" /* webpackChunkName: "component---src-layouts-acta-futura-js" */),
  "component---src-layouts-default-js": () => import("./../../../src/layouts/default.js" /* webpackChunkName: "component---src-layouts-default-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-interns-js": () => import("./../../../src/pages/about/interns.js" /* webpackChunkName: "component---src-pages-about-interns-js" */),
  "component---src-pages-about-jointheteam-js": () => import("./../../../src/pages/about/jointheteam.js" /* webpackChunkName: "component---src-pages-about-jointheteam-js" */),
  "component---src-pages-about-members-js": () => import("./../../../src/pages/about/members.js" /* webpackChunkName: "component---src-pages-about-members-js" */),
  "component---src-pages-about-research-fellows-js": () => import("./../../../src/pages/about/research_fellows.js" /* webpackChunkName: "component---src-pages-about-research-fellows-js" */),
  "component---src-pages-about-theteam-js": () => import("./../../../src/pages/about/theteam.js" /* webpackChunkName: "component---src-pages-about-theteam-js" */),
  "component---src-pages-about-whoweare-js": () => import("./../../../src/pages/about/whoweare.js" /* webpackChunkName: "component---src-pages-about-whoweare-js" */),
  "component---src-pages-about-whowewere-js": () => import("./../../../src/pages/about/whowewere.js" /* webpackChunkName: "component---src-pages-about-whowewere-js" */),
  "component---src-pages-about-ygts-js": () => import("./../../../src/pages/about/ygts.js" /* webpackChunkName: "component---src-pages-about-ygts-js" */),
  "component---src-pages-collab-ariadna-about-js": () => import("./../../../src/pages/collab/ariadna_about.js" /* webpackChunkName: "component---src-pages-collab-ariadna-about-js" */),
  "component---src-pages-collab-ariadna-calls-js": () => import("./../../../src/pages/collab/ariadna_calls.js" /* webpackChunkName: "component---src-pages-collab-ariadna-calls-js" */),
  "component---src-pages-collab-ariadna-faq-js": () => import("./../../../src/pages/collab/ariadna_faq.js" /* webpackChunkName: "component---src-pages-collab-ariadna-faq-js" */),
  "component---src-pages-collab-ariadna-js": () => import("./../../../src/pages/collab/ariadna.js" /* webpackChunkName: "component---src-pages-collab-ariadna-js" */),
  "component---src-pages-collab-ariadna-search-js": () => import("./../../../src/pages/collab/ariadna_search.js" /* webpackChunkName: "component---src-pages-collab-ariadna-search-js" */),
  "component---src-pages-collab-competitions-js": () => import("./../../../src/pages/collab/competitions.js" /* webpackChunkName: "component---src-pages-collab-competitions-js" */),
  "component---src-pages-collab-sciencecoffee-js": () => import("./../../../src/pages/collab/sciencecoffee.js" /* webpackChunkName: "component---src-pages-collab-sciencecoffee-js" */),
  "component---src-pages-cool-stuff-js": () => import("./../../../src/pages/cool-stuff.js" /* webpackChunkName: "component---src-pages-cool-stuff-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-resources-act-papers-js": () => import("./../../../src/pages/resources/act_papers.js" /* webpackChunkName: "component---src-pages-resources-act-papers-js" */),
  "component---src-pages-resources-act-papers-static-js": () => import("./../../../src/pages/resources/act_papers_static.js" /* webpackChunkName: "component---src-pages-resources-act-papers-static-js" */),
  "component---src-pages-resources-acta-futura-about-js": () => import("./../../../src/pages/resources/acta_futura_about.js" /* webpackChunkName: "component---src-pages-resources-acta-futura-about-js" */),
  "component---src-pages-resources-acta-futura-issues-js": () => import("./../../../src/pages/resources/acta_futura_issues.js" /* webpackChunkName: "component---src-pages-resources-acta-futura-issues-js" */),
  "component---src-pages-resources-acta-futura-js": () => import("./../../../src/pages/resources/acta_futura.js" /* webpackChunkName: "component---src-pages-resources-acta-futura-js" */),
  "component---src-pages-resources-acta-futura-search-js": () => import("./../../../src/pages/resources/acta_futura_search.js" /* webpackChunkName: "component---src-pages-resources-acta-futura-search-js" */),
  "component---src-pages-resources-acta-futura-static-js": () => import("./../../../src/pages/resources/acta_futura_static.js" /* webpackChunkName: "component---src-pages-resources-acta-futura-static-js" */),
  "component---src-pages-resources-software-js": () => import("./../../../src/pages/resources/software.js" /* webpackChunkName: "component---src-pages-resources-software-js" */),
  "component---src-pages-secret-js": () => import("./../../../src/pages/secret.js" /* webpackChunkName: "component---src-pages-secret-js" */)
}

