import { css } from 'styled-components'
import { withPrefix } from 'gatsby'

export const EsaNotesFont = css`
  @font-face {
    font-family: 'EsaNotes';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: url(${withPrefix('/fonts/NotesEsaReg.otf')}) format('opentype');
  }
  @font-face {
    font-family: 'EsaNotes';
    font-style: normal;
    font-weight: bold;
    font-display: block;
    src: url(${withPrefix('/fonts/NotesEsaBol.otf')}) format('opentype');
  }
  @font-face {
    font-family: 'EsaNotes';
    font-style: italic;
    font-weight: normal;
    font-display: block;
    src: url(${withPrefix('/fonts/NotesEsaRegIta.otf')}) format('opentype');
  }
  @font-face {
    font-family: 'EsaNotes';
    font-style: italic;
    font-weight: bold;
    font-display: block;
    src: url(${withPrefix('/fonts/NotesEsaBolIta.otf')}) format('opentype');
  }
`

export const SansSemiFont = css`
  @font-face {
    font-family: 'SansSemi';
    font-style: normal;
    font-weight: normal;
    src: url(${withPrefix('/fonts/TheSansOsF-SemiLight.otf')})
      format('opentype');
  }

  @font-face {
    font-family: 'SansSemi';
    font-style: normal;
    font-weight: bold;
    src: url(${withPrefix('/fonts/TheSansOsF-SemiBold.otf')}) format('opentype');
  }

  @font-face {
    font-family: 'SansSemi';
    font-style: italic;
    font-weight: normal;
    src: url(${withPrefix('/fonts/TheSansOsF-SemiLightItalic.otf')})
      format('opentype');
  }

  @font-face {
    font-family: 'SansSemi';
    font-style: italic;
    font-weight: bold;
    src: url(${withPrefix('/fonts/TheSansOsF-SemiBoldItalic.otf')})
      format('opentype');
  }
`

export const MetaSerifFont = css`
  @font-face {
    font-family: 'MetaSerif';
    font-style: normal;
    font-weight: normal;
    src: url(${withPrefix('/fonts/MetaSerifOT-Book.otf')}) format('opentype');
  }
  @font-face {
    font-family: 'MetaSerif';
    font-style: normal;
    font-weight: bold;
    src: url(${withPrefix('/fonts/MetaSerifOT-Bold.otf')}) format('opentype');
  }
  @font-face {
    font-family: 'MetaSerif';
    font-style: italic;
    font-weight: normal;
    src: url(${withPrefix('/fonts/MetaSerifOT-BookIta.otf')}) format('opentype');
  }
  @font-face {
    font-family: 'MetaSerif';
    font-style: italic;
    font-weight: bold;
    src: url(${withPrefix('/fonts/MetaSerifOT-BoldIta.otf')}) format('opentype');
  }
`

export const InconsolataFont = css`
  @font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: normal;
    src: url(${withPrefix('/fonts/Inconsolata-Regular.ttf')}) format('truetype');
  }
  @font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: bold;
    src: url(${withPrefix('/fonts/Inconsolata-Bold.ttf')}) format('truetype');
  }
`
