import React from 'react'
import styled, { css } from 'styled-components'
import { Link as GatsbyLink, withPrefix } from 'gatsby'

const linkStyles = css`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
`

const ExternalLink = styled.a`
  ${linkStyles};
`

const InternalGatsbyLink = styled(GatsbyLink)`
  ${linkStyles};
`

// Helper component to make it easier to handle the 3 different types of links
// see: https://gitlab.com/EuropeanSpaceAgency/act-website/-/wikis/Links-and-handling-URLs
export default function Link(props) {
  if (props.document) {
    const { document, to, ...restProps } = props
    return <ExternalLink {...restProps} href={withPrefix(document)} />
  }

  if (props.href) {
    return <ExternalLink {...props} />
  }

  if (!props.to) {
    // Render an span when no link is provided by any of the 3 options.
    return <span {...props} />
  }

  return <InternalGatsbyLink {...props} />
}
