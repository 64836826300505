import React from 'react'
import styled from 'styled-components'
import { withPrefix } from 'gatsby'

import { Grid } from '../shared/card-grid'

const pics = [
  'PIA16051-01.jpg',
  'PIA16051-02.jpg',
  'PIA16051-03.jpg',
  'PIA16051-04.jpg',
  'PIA16105-ALL.jpg',
  'PIA16154-01.jpg',
  'PIA16154-02.jpg',
  'PIA16154-03.jpg',
  'PIA16453-01.jpg',
  'PIA16453-02.jpg',
  'PIA16453-03.jpg',
  'PIA16453-04.jpg',
  'PIA16453-05.jpg',
  'PIA16453-06.jpg',
  'PIA16563-01.jpg',
  'PIA16563-02.jpg',
  'PIA16563-03.jpg',
  'PIA16700-01.jpg',
  'PIA16700-02.jpg',
  'PIA16700-03.jpg',
  'PIA16700-04.jpg',
  'PIA16700-05.jpg',
  'PIA16701-01.jpg',
  'PIA16701-02.jpg',
  'PIA16701-03.jpg',
  'PIA16701-04.jpg',
  'PIA16701-05.jpg',
  'PIA16701-06.jpg',
  'PIA16706-ALL.jpg',
  'PIA16707-ALL.jpg',
  'PIA16768-01.jpg',
  'PIA16768-02.jpg',
  'PIA16768-03.jpg',
  'PIA16768-04.jpg',
  'PIA16803-ALL.jpg',
  'PIA16804-ALL.jpg',
  'PIA16918-01.jpg',
  'PIA16918-02.jpg',
  'PIA16918-03.jpg',
  'PIA16918-04.jpg',
  'PIA17062-ALL.jpg',
  'PIA17071-ALL.jpg',
  'PIA17083-01.jpg',
  'PIA17083-02.jpg',
  'PIA17083-03.jpg',
  'PIA17268-01.jpg',
  'PIA17268-02.jpg',
  'PIA17268-03.jpg',
]

const Link = styled.a`
  display: block;
  cursor: pointer;
  width: 100%;
`

const Image = styled.img`
  display: block;
  width: 100%;
`

export default function EyetrackerImages() {
  return (
    <Grid>
      {pics.map((pic) => {
        return (
          <Link href={withPrefix(`/images/projects/eyetracker/${pic}`)}>
            <Image src={withPrefix(`/images/projects/${pic}`)} />
          </Link>
        )
      })}
    </Grid>
  )
}
