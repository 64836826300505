import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'

import { lightColors } from './color-context'

import { EsaNotesFont, InconsolataFont } from './fonts'

export default createGlobalStyle`
  ${normalize()};

  ${EsaNotesFont};
  ${InconsolataFont};

  :root {
    --gray-0: #1a1a1a;
    --gray-1: #262626;
    --gray-4: #666666;
    --gray-6: #b3b3b3;
    --gray-7: #cccccc;
    --gray-8: #f2f2f2;
    --gray-9: #ffffff;
    --orange: #FFA800;
    --orange-dark: #9b843e;
    --accent-bright: var(--orange);
    --accent-dark: var(--orange-dark);
    --column-content-width: 964px;
    --column-text-width: 792px;
    --font-body: Verdana, Arial, -apple-system, BlinkMacSystemFont,
      'Helvetica Neue', Helvetica, sans-serif;
    --font-heading: 'EsaNotes', -apple-system, BlinkMacSystemFont,
      'Helvetica Neue', Helvetica, sans-serif;
        --font-mono: 'Inconsolata', SFMono-Regular, Consolas, Liberation Mono, Menlo,
      monospace;

    ${lightColors};
}

  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    -webkit-overflow-scrolling: touch;
  }

  html,
  body {
    width: 100%;
    background-color: var(--background);
    font-family: var(--font-body);
  }

  body {
    box-sizing: border-box;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga' 1, 'case' 1, 'calt' 1;
    text-align: left;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
  a {
    cursor: pointer;
    color: var(--accent-bright);
    text-decoration: none;

    :hover {
        text-decoration: underline;
    }
  }
`
