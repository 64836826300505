import React from 'react'

export default function ArrowRightIcon(props) {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 11.428"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(19380 17820)">
        <path
          d="M13.885,128.157l-.316.316a.536.536,0,0,0,0,.758l3.747,3.725H.536a.536.536,0,0,0-.536.536v.446a.536.536,0,0,0,.536.536H17.316L13.57,138.2a.536.536,0,0,0,0,.758l.316.316a.536.536,0,0,0,.758,0l5.2-5.179a.536.536,0,0,0,0-.758l-5.2-5.179A.536.536,0,0,0,13.885,128.157Z"
          transform="translate(-19380 -17948)"
        />
      </g>
    </svg>
  )
}
