import styled from 'styled-components'

const Blur = styled.div`
  transition: 0.25s filter linear;
  display: block;
  filter: blur(4px);

  :hover {
    filter: blur(0px);
  }
`

export default Blur
