import { css } from 'styled-components'

import { screen } from './breakpoints'

export const absoluteSize = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

function textOrContentWidth(props) {
  if (props.textColumn) return '--column-text-width'
  return '--column-content-width'
}

export const centerColumn = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: calc(var(${textOrContentWidth}) + 2 * 20px);

  @media ${screen.sm} {
    padding-left: 50px;
    padding-right: 50px;
    max-width: calc(var(${textOrContentWidth}) + 2 * 50px);
  }

  @media ${screen.md} {
    padding-left: 130px;
    padding-right: 130px;
    max-width: calc(var(${textOrContentWidth}) + 2 * 130px);
  }
`

export const backgroundColor = css`
  ${(props) =>
    props.bgColor === 'background' &&
    css`
      background-color: var(--background);
    `}

  ${(props) =>
    props.bgColor === 'surface' &&
    css`
      background-color: var(--surface);
    `}
`
