import styled from 'styled-components'

import { screen } from './breakpoints'

export default styled.h1`
  font-weight: bold;
  font-size: 2.33rem;
  margin-top: 0;
  margin-bottom: 0.5em;
  font-family: var(--font-heading);
  line-height: 1.35;
  color: var(--heading);

  @media ${screen.sm} {
    font-size: 2.75rem;
  }

  @media ${screen.md} {
    font-size: 3rem;
  }
`
