import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { useMove, subV } from 'react-use-gesture'

import RocketIcon from './icons/rocket'
import { makeSpringConfig } from './spring'

const IconWrapper = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  color: var(--accent-bright);
  pointer-events: none;
  transform-origin: center;
`

let windowReference
let initialX = 0
let initialY = 0
if (typeof window !== 'undefined') {
  windowReference = window
  initialX = window.innerWidth / 2
  initialY = window.innerHeight
}

const fourthPI = Math.PI / 4
const deltaAngleThreshold = (270 / 180) * Math.PI

export default function RocketCursor(props) {
  const [isVisible, setIsVisible] = useState(false)
  const [{ position }, setPosition] = useSpring(() => ({
    position: [initialX, initialY],
    config: makeSpringConfig(),
  }))

  const [{ angle }, setAngle] = useSpring(() => ({
    angle: 0,
    config: makeSpringConfig({ response: 250 }),
  }))

  const bind = useMove(
    ({ xy, first, memo = {} }) => {
      // Memorize from previous event
      const { prevAngle = angle.getValue(), turns = 0 } = memo

      if (first) setIsVisible(true)

      const deltaPos = subV(xy, position.getValue())

      let newAngle = Math.atan2(deltaPos[1], deltaPos[0])
      // The angle value might jump from 179deg to -179deg when we actually want to
      // read 181deg to ensure continuity. To make that happen, we detect when the jump
      // is suspiciously high (ie > 270deg) and increase the `turns` value
      let deltaAngle = newAngle - 2 * Math.PI * turns - prevAngle
      let newTurns = turns
      if (Math.abs(deltaAngle) > deltaAngleThreshold) {
        // update the angle difference to its corrected value
        newTurns += Math.sign(deltaAngle)
        deltaAngle -= 2 * Math.PI * Math.sign(deltaAngle)
      }

      newAngle = prevAngle + deltaAngle

      setPosition({ position: xy })
      setAngle({ angle: newAngle })
      // Memorize for use in handling next event
      return { angle: newAngle, turns: newTurns }
    },
    { domTarget: windowReference }
  )
  // binds event handlers to the window
  useEffect(bind, [bind])

  // Makes sure the rocket won't render when the user doesn't have a pointer (mouse)
  if (!isVisible) return null

  const transform = position.interpolate(
    (x, y) =>
      `translate(${x}px,${y}px) rotate(${angle.getValue() + fourthPI}rad)`
  )

  return (
    <IconWrapper style={{ transform }}>
      <RocketIcon height={24} />
    </IconWrapper>
  )
}
