import React, { useContext, useMemo } from 'react'
import styled, { css, ThemeProvider, ThemeContext } from 'styled-components'

import { backgroundColor } from './common-styles'

export const darkColors = css`
  --heading: var(--gray-9);
  --text: var(--gray-8);
  --background: var(--gray-1);
  --surface: var(--gray-0);
  --subtle: var(--gray-6);
  --super-subtle: var(--gray-4);
`

export const lightColors = css`
  --heading: var(--gray-0);
  --text: var(--gray-1);
  --background: var(--gray-8);
  --surface: var(--gray-9);
  --subtle: var(--gray-4);
  --super-subtle: var(--gray-6);
`

const CSSVariable = styled.div`
  ${(props) => props.theme.name === 'dark' && darkColors}
  ${(props) => props.theme.name === 'light' && lightColors}
  ${backgroundColor};
`

export default function ColorContext({ theme: themeName, ...restProps }) {
  const currentTheme = useContext(ThemeContext)

  const theme = useMemo(
    () => ({
      ...currentTheme,
      name: themeName,
    }),
    [currentTheme, themeName]
  )

  return (
    <ThemeProvider theme={theme}>
      <CSSVariable {...restProps} />
    </ThemeProvider>
  )
}
