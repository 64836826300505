import React from 'react'
import styled from 'styled-components'
import { withPrefix } from 'gatsby'

import { absoluteSize } from './common-styles'
import ArrowRightIcon from './icons/arrow-right'
import Link from './link'

// Grid component to display cards.
export const Grid = styled.div.attrs({ role: 'list' })`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-gap: 20px;
  justify-items: center;
`

const StyledCard = styled(Link)`
  color: inherit;
  text-decoration: none;
  position: relative;
  max-width: 275px;
  width: 100%;
  transition: transform 250ms ease;
  will-change: transform;

  :hover {
    transform: translateY(-4px);
  }
`

const ChildWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  background-color: var(--surface);
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const ShadowSmall = styled.div`
  ${absoluteSize};
  opacity: 1;
  will-change: opacity;
  transition: opacity 250ms ease;
  border-radius: 2px;
  box-shadow: 0 0.4px 0.8px rgba(0, 0, 0, 0.011), 0 1px 2px rgba(0, 0, 0, 0.016),
    0 1.9px 3.8px rgba(0, 0, 0, 0.02), 0 3.4px 6.7px rgba(0, 0, 0, 0.024),
    0 6.3px 12.5px rgba(0, 0, 0, 0.029), 0 15px 30px rgba(0, 0, 0, 0.04);

  ${StyledCard}:hover & {
    opacity: 0;
  }
`

const ShadowBig = styled.div`
  ${absoluteSize};
  opacity: 0;
  will-change: opacity;
  transition: opacity 250ms ease;
  border-radius: 2px;
  box-shadow: 0 0.6px 1px rgba(0, 0, 0, 0.017),
    0 1.5px 2.3px rgba(0, 0, 0, 0.024), 0 2.9px 4.4px rgba(0, 0, 0, 0.03),
    0 5.1px 7.8px rgba(0, 0, 0, 0.036), 0 9.6px 14.6px rgba(0, 0, 0, 0.043),
    0 23px 35px rgba(0, 0, 0, 0.06);

  ${StyledCard}:hover & {
    opacity: 1;
  }
`

const StyledCardActionCall = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5px;
  color: var(--text);

  svg {
    display: block;
    transition: transform 250ms ease;
  }

  ${StyledCard}:hover & svg {
    transform: translateX(5px);
  }
`

export function CardActionCall({ children, ...restProps }) {
  return (
    <StyledCardActionCall {...restProps}>
      <span css="flex-grow: 1;">{children}</span>
      <ArrowRightIcon css="height: 12px;" />
    </StyledCardActionCall>
  )
}

export function Card(props) {
  const { children, ...restProps } = props
  return (
    <StyledCard role="listitem" {...restProps}>
      <ShadowSmall />
      <ShadowBig />
      <ChildWrapper>{children}</ChildWrapper>
    </StyledCard>
  )
}

const MinimalCardImage = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
`

export const CardTextGroup = styled.div`
  padding: 20px;
  font-family: var(--font-heading);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`

export function MinimalCard(props) {
  const { src, call, ...restProps } = props

  return (
    <Card {...restProps}>
      <figure css="position: relative;">
        <MinimalCardImage src={withPrefix(src)} alt={call} />
      </figure>
      <CardTextGroup>
        <CardActionCall>{call}</CardActionCall>
      </CardTextGroup>
    </Card>
  )
}

const StyledMoreCard = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  min-height: 300px;
  max-width: 275px;
`

const MoreCardContent = styled.div`
  border: 2px dashed var(--subtle);
  color: var(--subtle);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-heading);
  font-weight: bold;
  font-size: 1.25rem;
  transition: opacity 100ms ease-out;
  text-align: center;
  padding: 15px;

  :active {
    opacity: 0.5;
  }
`

export function MoreCard(props) {
  return (
    <StyledMoreCard role="button" {...props}>
      <MoreCardContent>{props.children}</MoreCardContent>
    </StyledMoreCard>
  )
}
