/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const React = require('react')

const AppContext = require('./src/shared/app-context').default

exports.wrapPageElement = function ({ element, props }) {
  return <AppContext {...props}>{element}</AppContext>
}
