import React from 'react'

export default function RocketIcon(props) {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.001 512.001"
      fill="currentColor"
      {...props}
    >
      <path
        d="M268.662,243.351c-3.91-3.91-10.24-3.91-14.15,0c-3.9,3.9-3.9,10.23,0,14.14c3.91,3.9,10.24,3.9,14.15,0
			C272.562,253.581,272.562,247.251,268.662,243.351z"
      />
      <path
        d="M395.933,116.07c-19.54-19.539-51.172-19.54-70.713,0c-19.489,19.489-19.49,51.209,0.003,70.714
			c19.496,19.484,51.216,19.492,70.71-0.002C415.471,167.244,415.475,135.611,395.933,116.07z M381.791,172.639
			c-11.693,11.694-30.727,11.694-42.426,0.002c-11.695-11.702-11.696-30.736-0.002-42.429c11.723-11.723,30.703-11.725,42.428,0
			C393.514,141.934,393.516,160.915,381.791,172.639z"
      />
      <path
        d="M509.07,2.929c-1.964-1.963-4.644-3.027-7.43-2.922c-2.261,0.081-56.096,2.245-120.091,24.864
			c-51.28,18.106-94.023,44.029-127.042,77.049c-7.399,7.399-14.599,15.245-21.488,23.396c-32.648-19.218-58.81-13.192-75.349-4.341
			c-38.081,20.38-61.652,75.716-61.652,115.296c0.003,4.044,2.44,7.691,6.176,9.238c1.237,0.513,2.537,0.762,3.825,0.762
			c2.603,0,5.16-1.017,7.073-2.929c20.083-20.083,44.854-18.827,52.946-17.763l3.502,3.502c-6.892,16.4-12.444,32.708-16.516,48.569
			c-1.47,5.74-0.974,11.814,1.211,17.37c-9.628,4.437-18.917,10.952-27.005,19.04c-25.741,25.742-30.968,88.476-31.178,91.134
			c-0.23,2.917,0.828,5.789,2.897,7.858c1.883,1.882,4.43,2.929,7.07,2.929c0.262,0,0.525-0.01,0.788-0.031
			c2.658-0.209,65.394-5.436,91.135-31.177c8.085-8.085,14.599-17.373,19.036-26.999c5.882,2.313,11.936,2.598,17.38,1.203
			c15.854-4.071,32.16-9.621,48.562-16.514l3.502,3.502c1.063,8.093,2.319,32.864-17.763,52.945
			c-2.859,2.86-3.715,7.161-2.167,10.897c1.547,3.737,5.193,6.173,9.238,6.173c39.58,0,94.915-23.571,115.295-61.652
			c8.851-16.537,14.877-42.699-4.341-75.348c8.147-6.886,15.994-14.086,23.396-21.488c33.02-33.02,58.942-75.763,77.048-127.039
			c22.62-63.998,24.783-117.834,24.864-120.094C512.092,7.584,511.033,4.893,509.07,2.929z M118.376,214.762
			c6.212-30.063,24.255-63.052,48.735-76.154c16.359-8.754,34.24-7.896,53.252,2.511c-16.197,21.302-30.278,44.252-42.015,68.487
			c-0.149-0.092-1.949-2.355-5.293-3.109C171.68,206.186,145.221,200.495,118.376,214.762z M183.8,370.63
			c-13.75,13.75-46.005,21.002-66.392,23.963c2.962-20.388,10.215-52.642,23.964-66.391c7.7-7.7,16.628-13.538,25.602-16.826
			l33.652,33.652C197.338,354.002,191.501,362.93,183.8,370.63z M229.39,339.603c-2.894,0.741-6.246-0.347-8.738-2.835
			c-12.937-12.937-17.998-17.998-19.586-19.585c-2.912-2.912-10.053-10.053-25.826-25.827c-2.494-2.499-3.582-5.85-2.843-8.739
			c3.203-12.474,7.392-25.272,12.486-38.193l82.695,82.695C254.655,332.214,241.859,336.402,229.39,339.603z M373.394,344.891
			c-13.102,24.479-46.09,42.523-76.152,48.734c9.585-18.037,11.698-40.998,8.196-54.921c-0.813-3.234-2.923-4.86-3.041-5.051
			c24.233-11.737,47.182-25.818,68.486-42.015C381.29,310.652,382.147,328.535,373.394,344.891z M395.94,243.35
			c-9.011,9.01-18.714,17.715-28.84,25.872c-24.342,19.6-51.134,36.202-79.718,49.418l-94.02-94.018
			c13.216-28.586,29.818-55.378,49.416-79.717c8.164-10.133,16.868-19.837,25.875-28.843
			c29.625-29.626,67.859-53.204,113.671-70.176l83.792,83.792C449.146,175.489,425.568,213.723,395.94,243.35z M473.311,108.587
			l-69.896-69.896c38.081-11.828,71.21-16.257,87.746-17.849C489.568,37.381,485.138,70.51,473.311,108.587z"
      />
      <path
        d="M240.371,413.049c-3.907-3.905-10.239-3.903-14.143,0.002l-28.28,28.29c-3.904,3.906-3.903,10.238,0.002,14.142
			c3.907,3.904,10.239,3.904,14.143-0.002l28.28-28.29C244.277,423.285,244.276,416.953,240.371,413.049z"
      />
      <path
        d="M98.954,271.631c-3.904-3.906-10.235-3.906-14.142-0.002l-28.29,28.28c-3.906,3.904-3.907,10.236-0.003,14.142
			c3.903,3.904,10.234,3.908,14.142,0.002l28.29-28.28C102.857,281.869,102.858,275.537,98.954,271.631z"
      />
      <path
        d="M169.663,427.2c-3.903-3.905-10.236-3.905-14.142-0.001l-67.74,67.73c-3.906,3.905-3.906,10.237-0.001,14.142
			c3.903,3.905,10.236,3.907,14.142,0.001l67.74-67.73C173.568,437.437,173.568,431.105,169.663,427.2z"
      />
      <path
        d="M89.663,427.2c-3.904-3.905-10.237-3.905-14.142-0.001l-67.74,67.73c-3.906,3.905-3.906,10.237-0.001,14.142
			c3.903,3.905,10.236,3.907,14.142,0.001l67.74-67.73C93.568,437.437,93.568,431.105,89.663,427.2z"
      />
      <path
        d="M84.802,342.338c-3.904-3.904-10.237-3.904-14.142,0.001l-67.73,67.74c-3.905,3.906-3.905,10.237,0.001,14.143
			c3.905,3.905,10.237,3.905,14.142-0.001l67.73-67.74C88.708,352.575,88.708,346.244,84.802,342.338z"
      />
      <path
        d="M311.083,200.919c-3.906-3.905-10.236-3.905-14.143,0l-14.14,14.14c-3.905,3.905-3.905,10.237,0,14.143
			c3.906,3.905,10.237,3.905,14.143,0l14.14-14.14C314.988,211.157,314.988,204.825,311.083,200.919z"
      />
    </svg>
  )
}
