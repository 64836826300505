import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'

import SectionHeader from './section-header'
import { absoluteSize } from './common-styles'
import { screen } from './breakpoints'

export const h1 = styled(SectionHeader)`
  clear: both;
  margin-top: 0.5em;
  margin-bottom: 0.25em;
`

export const h2 = styled.h1`
  clear: both;
  font-size: 1.75rem;
  color: var(--heading);
  margin: 1.75em 0 0.75em;
  font-family: var(--font-heading);
`

export const h3 = styled.h1`
  clear: both;
  font-size: 1.418rem;
  color: var(--heading);
  margin: 1.5em 0 0.5em;
  font-family: var(--font-heading);
`

export const p = styled.p`
  font-size: 1rem;
  margin: 1.125em 0;
  color: var(--text);
  line-height: 1.5;

  &.abstract {
    font-size: 1.125rem;
  }
`

export const ol = styled(p).attrs({ as: 'ol' })`
  padding-left: 30px;
  color: var(--text);
  line-height: 1.5;
`

export const ul = styled(ol).attrs({ as: 'ul' })``

export const li = styled.li`
  margin-bottom: 4px;

  :last-child {
    margin-bottom: 0px;
  }
`

const StyledAnchor = styled.a`
  cursor: pointer;
  color: var(--accent-bright);
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

function Anchor(props) {
  const { href, ...restProps } = props
  let prefixedPath = href

  const { site } = useStaticQuery(graphql`
    {
      site {
        pathPrefix
      }
    }
  `)

  // assume that all urls that start with a / need to be prefixed
  // this assumption seems to be working fine
  if (href?.startsWith('/')) {
    // the link prefix behavious seem to be random
    // so to normalize the behaviour we remove the prefix if it is already added
    const unprefixedPath = href.replace(site.pathPrefix, '')
    // then we add it for sure
    prefixedPath = withPrefix(unprefixedPath)
  }

  return <StyledAnchor href={prefixedPath} {...restProps} />
}

export { Anchor as a }

export const hr = styled.hr`
  clear: both;
  border: 2px solid var(--super-subtle);
  border-top: none;
  border-left: none;
  margin: 30px auto;
  opacity: 0.33;

  @media ${screen.md} {
    margin: 50px auto;
  }
`

export const table = styled.table`
  width: 100%;
`

export const IframeWrapper = styled.iframe`
  width: 100%;
`

const Ratio = styled.div`
  position: relative;
  padding-bottom: ${(props) => props.ratio * 100}%;
`

export function iframe(props) {
  if (props.width && props.height) {
    // Make sure the aspect ratio of the iframe is consistent while being responsive.
    return (
      <div css="position: relative; width: 100%;">
        <Ratio ratio={props.height / props.width} />
        <IframeWrapper css={absoluteSize} {...props} />
      </div>
    )
  }

  return <IframeWrapper {...props} />
}

/*
  .outcome-list {
    list-style: upper-roman;
  }
*/
