import React from 'react'
import styled, { css } from 'styled-components'
import { withPrefix } from 'gatsby'

import { screen } from './breakpoints'

/*
align = left|right|center
width = width in pixels (scales image)
height = height in pixels (scales image)
image_src = address of the image file, usually /core/images/...
caption = text that appears below the image (text may contain markdown)
*/

const Figure = styled.figure`
  border: 4px solid var(--background);
  border-radius: 2px;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  margin: 8px auto;

  ${(props) =>
    props.align === 'left' &&
    css`
      @media ${screen.sm} {
        float: left;
        margin: 8px 20px 8px 0px;
      }
    `}

  ${(props) =>
    props.align === 'right' &&
    css`
      @media ${screen.sm} {
        float: right;
        margin: 8px 0 8px 20px;
      }
    `}
`

const Caption = styled.figcaption`
  font-size: 0.75rem;
  color: var(--subtle);
  background-color: var(--background);
  padding: 6px 6px 2px;
`

const Img = styled.img`
  width: 100%;
  display: block;
`

export default function Image(props) {
  const { src, caption, width, height, ...restProps } = props
  return (
    <Figure maxWidth={width ?? height} {...restProps}>
      <Img
        align={props.align}
        src={withPrefix(props.src || props.image_src)}
        alt={props.caption}
      />
      {props.caption && <Caption>{props.caption}</Caption>}
    </Figure>
  )
}
